<template>
  <div class="app_srv">
    <div class="page_srv">
      <div class="back_container_srv" @click="GoBack()">
        <div class="back_ico_srv">
          <q-icon name="arrow_back" size="20px"> </q-icon>
          <div class="back_txt_srv">Back</div>
        </div>
      </div>
      <div v-for="row in rows.slice(findex, lindex)" :key="row.id">
        <p class="p1_srv">
          {{ row.title }}
        </p>
        <div class="entry-content">
            <div class="left_container_srv">
                <div class="completed_data">
                    <p class="p2_srv">
                        <span v-html="row.text"></span>
                    </p>
                </div>
            </div>
            <div class="right_container_srv">
                    <div v-for="slide1 in row.serviceImages.slice(0, 1)" :key="slide1.rowId" :name="slide1.rowId">
                        <img v-bind:src="slide1.img" style="width: 100%; aspect-ratio: 1 / 1; object-fit: cover;" @click="openModal(row.id);currentSlide(slide1.rowNumber)" class="hover-shadow cursor">
                    </div>
                    <div class="row">
                        <div v-for="slide1 in row.serviceImages.slice(1, 5)" :key="slide1.rowId" :name="slide1.rowId" class="column">
                            <img v-bind:src="slide1.img" style="width: 100%; aspect-ratio: 1 / 1; object-fit: cover;" @click="openModal(row.id);currentSlide(slide1.rowNumber)" class="hover-shadow cursor">
                        </div>
                    </div>
                    <div :id="`myModal-${row.id}`" class="modal">
                        <span class="close cursor" @click="closeModal(row.id)">&times;</span>
                        <div class="modal-content">
                            <div v-for="slide1 in row.serviceImages" :key="slide1.rowId" :name="slide1.rowId" :id="row.id" class="mySlides">
                                <img v-bind:src="slide1.img" style="width: 100%; aspect-ratio: 1 / 1;" @click="openModal(row.id);currentSlide(slide1.rowNumber)" class="hover-shadow cursor">
                            </div>
                            <a class="prev" @click="plusSlides(-1, row.id)">&#10094;</a>
                            <a class="next" @click="plusSlides(1, row.id)">&#10095;</a>

                            <div class="caption-container">
                                <p id="caption"></p>
                            </div>

                            <div v-for="slide in row.serviceImages" :key="slide.rowId" :name="slide.rowId" class="column" style="">
                                <img v-bind:src="slide.img" style="width: 100%; aspect-ratio: 1 / 1; object-fit: cover;" @click="currentSlide(slide.rowNumber)" class="demo cursor">
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div v-if="!checkLength(row.image)" class="right_container_srv">
        <div style="width: 100%; height: 350px" />
    </div>-->
            </div>
        </div>
      <div class="paginate_div">
        <paginate
          class="paginate_class"
          :page-count="this.pageCount"
          :margin-pages="2"
          :page-range="3"
          :click-handler="clickCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from "vue";
import servicesService from "../services/servicesService";
import { useStore } from "vuex";
import Paginate from "vuejs-paginate-next";

export default {
  routername: "Service",
        components: { Paginate },
        setup() {
            var rows = ref([]);
      return {
          autoplay: ref(true),
          slide: 1,
          row: ref("0"),
          rows
      }
  },
  data() {
      return {
      service: "",
      categ1: "",
      categ2: "",
      svisibleLightBox: false,
      images: ref([]),
      page: 1,
      pageCount: 0,
      slideIndex: 1,
      findex: 0,
          lindex: 5,
          testimages: [
              "/uploads/services/2023/2/14/Αρχιτεκτονική-Μελέτη.jpg",
              "/uploads/services/2023/2/14/Στατικές-Μελέτες.jpg"
          ]
    };
  },
  mounted() {
    const route = useRoute();
    this.service = route.params.routername;

    this.fetchData();
  },
  methods: {
    clickCallback(pageNum) {
      if (this.page < pageNum) {
        this.findex += (pageNum - this.page) * 5;
        this.lindex += (pageNum - this.page) * 5;
      } else {
        this.findex -= (this.page - pageNum) * 5;
        this.lindex -= (this.page - pageNum) * 5;
      }

      this.page = pageNum;
      window.scrollTo(0, 0);
    },
    fetchData() {
      const store = useStore();
      store.dispatch("show");
      var array = this.service.split("&");
      this.categ1 = array[0];
      this.categ2 = array[1];

      servicesService
        .get(this.categ1, this.categ2)
        .then((response) => {
            this.rows = response.data;
            console.log(response.data);
            this.pageCount = this.rows.length / 5;
          const lang = localStorage.getItem("lang");
          
          for (var i = 0; i < this.rows.length; i++) {
            this.images[i] = this.rows[i].image;
            if (lang == "en") {
              this.rows[i].text = this.rows[i].text_en;
              this.rows[i].title = this.rows[i].title_en;
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
          .finally(() => {
              console.log(this.rows);
          setTimeout(() => {
            store.dispatch("hide");
          }, 500);
        });
    },
    hideSpinner() {
      useStore().dispatch("hide");
    },
    visibleLightBox() {
      this.svisibleLightBox = true;
    },
    handleHide() {
      this.svisibleLightBox = false;
    },
    checkLength(string) {
      if (string == null) {
        return false;
      }
      if (string.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    GoBack() {
      this.$router.push("/Services");
    },
      openModal(id) {
        document.getElementsByTagName("body")[0].setAttribute("style", "overflow-y: hidden;");

        document.getElementById("myModal-"+id).setAttribute("style", "display: block");
    },
      closeModal(id) {
          document.getElementsByTagName("body")[0].removeAttribute("style");
        document.getElementById("myModal-"+id).setAttribute("style", "display: none");
    },
      plusSlides(n, rowId) {
          var slides = document.getElementsByClassName("mySlides");
          if (slides[this.slideIndex - 1 + n] == undefined || slides[this.slideIndex - 1 + n].getAttribute("id") != rowId)
              return;
        this.showSlides(this.slideIndex += n);
    },
    currentSlide(n) {
        this.showSlides(this.slideIndex = n);
      },
    showSlides(n) {
        var i;
        var slides = document.getElementsByClassName("mySlides");
        var dots = document.getElementsByClassName("demo");

        var captionText = document.getElementById("caption");
        if (n > slides.length) { this.slideIndex = 1 }
        if (n < 1) { this.slideIndex = slides.length }
        for (i = 0; i < slides.length; i++) {
            slides[i].setAttribute("style", "display: none")
        }
        for (i = 0; i < dots.length; i++) {
            dots[i].className = dots[i].className.replace(" active", "");
        }
        slides[this.slideIndex - 1].setAttribute("style", "display: block");
        //dots[slideIndex - 1].setAttribute("class", " active");
        //slides[slideIndex - 1].style.display = "block";
        dots[this.slideIndex - 1].className += " active";
        captionText.innerHTML = dots[this.slideIndex - 1].alt;
    }
  },
};
</script>

<style>
.app_srv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.carousel_container {
    height: 100%;
}

.carousel_slide {
    height: 100%;
}

.paginate_class {
    list-style: none;
}

.paginate_div {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    padding-top: 50px;
}

.page-item {
    color: black;
    float: left;
    text-decoration: none;
}

.page-item.active .page-link {
    background-color: #4caf50;
    color: white;
}

.page-link {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: black;
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(222, 226, 230);
    border-image: initial;
    transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s, border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}

.page-link:hover {
    background-color: #ddd;
    cursor: pointer;
}

.page_srv {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
  width: 100%;
}

.back_container_srv {
  display: flex;
  align-items: center;
}

.back_container_srv :hover {
  cursor: pointer;
  color: #00afea;
  transition: color 0.3s;
}

.back_ico_srv {
  display: flex;
  align-items: center;
}

.back_txt_srv {
  font-size: 17px;
}

.img_stl {
  background-size: cover;
  width: 100%;
  height: 350px;
}

.entry-content {
  display: flex;
  height: auto;
}

.completed_data {
  margin-bottom: 60px;
}

.left_container_srv {
  flex: 0;
  flex-basis: calc(60% - 10px);
  max-width: calc(60% - 10px);
  position: relative;
}

    .right_container_srv {
        flex: 0;
        flex-basis: calc(40% - 15px);
        max-width: calc(40% - 15px);
        position: relative;
        padding-left: 25px;
        text-align: left;
        aspect-ratio: 1;
        max-height: 500px;
        display: inline-table;
    }

.panel2 {
  background-size: cover;
  min-width: 100%;
  min-height: 350px;
}

.p1_srv {
  font-size: 25px;
  text-align: left;
  font-weight: 300;
  color: black;
  font-family: "Arial";
  padding-top: 30px;
  min-width: 100%;
}

.p2_srv {
  font-size: 20px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 10px;
}

@media screen and (max-width: 1550px) {
  .app_srv {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1330px) {
  .app_srv {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 1130px) {
  .app_srv {
    padding: 0 30px 10px 30px;
  }
  .p1_srv {
    font-size: 18px;
  }
  .p2_srv {
    font-size: 15px;
  }
}

@media screen and (max-width: 1070px) {
  .app_srv {
    padding: 0 10px 5px 10px;
  }
  .page_srv {
    padding: 30px 25px 30px 25px;
  }
}

@media screen and (max-width: 980px) {
  .app_srv {
    padding: 0;
    display: unset;
  }

  .page_srv {
    padding: 30px 10px 30px 10px;
  }

  .entry-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .left_container_srv {
    max-width: 100%;
  }

  .right_container_srv {
    max-width: 100%;
    padding: 0;
  }
}

img.hover-shadow {
    transition: 0.3s;
}

.hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.row > .column {
    padding: 0 8px;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

    .column {
        float: left;
        width: 20%;
        padding: 5px
    }
    /* The Modal (background) */
    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.9);
    }

    /* Modal Content */
    .modal-content {
        position: relative;
        background-color: #fefefe;
        margin: auto;
        padding: 0;
        width: 90%;
        max-width: 1200px;
    }

    /* The Close Button */
    .close {
        color: white;
        position: absolute;
        top: 10px;
        right: 25px;
        font-size: 35px;
        font-weight: bold;
    }

        .close:hover,
        .close:focus {
            color: #999;
            text-decoration: none;
            cursor: pointer;
        }

    .mySlides {
        display: none;
    }

    .cursor {
        cursor: pointer;
    }

    /* Next & previous buttons */
    .prev,
    .next {
        cursor: pointer;
        position: absolute;
        top: 50%;
        width: auto;
        padding: 16px;
        margin-top: -50px;
        color: white;
        font-weight: bold;
        font-size: 20px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        -webkit-user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
        right: 0;
        border-radius: 3px 0 0 3px;
    }

        /* On hover, add a black background color with a little bit see-through */
        .prev:hover,
        .next:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }

    /* Number text (1/3 etc) */
    .numbertext {
        color: #f2f2f2;
        font-size: 12px;
        padding: 8px 12px;
        position: absolute;
        top: 0;
    }
</style>

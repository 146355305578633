import api from "../api";

class usersService {
  async getAll() {
    return api.get("/DashBoard/Users");
  }

  async get(id) {
    return api.get(`/DashBoard/Users/${id}`);
  }

  async create(data) {
    return api.post("/DashBoard/Users/", data);
  }
  async delete(id) {
    return api.delete(`/DashBoard/Users/${id}`);
   }
}

export default new usersService();
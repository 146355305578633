<template>
  <header
    class="q-header q-layout__section--marginal fixed-top"
    style="height: 100%; background-color: #f1f1f1"
  >
    <div class="login_container">
      <img src="../../assets/WBlogo.gif" class="img_logo" />
      <div class="login_form">
        <div class="text-label">Username or Email</div>
        <q-input
          v-model="username"
          class="input_text"
          outlined
          bg-color="white"
          type="text"
          :dense="dense"
          aria-required="true"
          aria-invalid="false"
        />
        <div class="text-label">Password</div>
        <q-input
          v-model="password"
          class="input_text"
          outlined
          bg-color="white"
          :type="isPwd ? 'password' : 'text'"
          :dense="dense"
          aria-required="true"
          aria-invalid="false"
        >
          <template #append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
        <div class="button_container">
          <q-btn
            class="login_button"
            push
            color="light-blue-9"
            label="Log in"
            @click="onClickLogin()"
          />
        </div>
        <div />
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from "vue";
import loginService from "../../services/loginService";

export default {
  setup() {
    return {
      password: ref(""),
      username: ref(""),
      data: ref({}),
      isPwd: ref(true),
    };
  },
  methods: {
    onClickLogin() {
      this.data.UserName = this.username;
      this.data.Password = this.password;

      loginService
        .create(this.data)
        .then((response) => {
          this.$router.push("/DashBoard");
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.login_container {
  font-family: "Arial";
  color: black;
  width: 320px;
  padding: 8% 0 0;
  margin: auto;
}

.img_logo {
  height: 100%;
  width: 100%;
}

.login_form {
  margin-top: 20px;
  margin-left: 0;
  padding: 26px 24px 46px;
  font-weight: 400;
  overflow: hidden;
  background: #fff;
  border: 1px solid #ccd0d4;
  box-shadow: 0 1px 3px rgb(0 0 0 / 4%);
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 3px;
  text-align: left;
}

.text-label {
  padding: 0;
}

.input_text {
  padding-bottom: 15px;
  padding-top: 3px;
}

.label_text {
  padding-top: 15px;
  float: right;
  width: 100%;
}

.label_text:hover {
  cursor: pointer;
  color: #555d92;
  transition: color 0.3s;
}

.button_container {
  width: 100%;
}
.login_button {
  margin-right: 0;
  float: right;
}
</style>

<template>
  <div class="carousel_container">
    <q-carousel
      v-model="slide"
      class="carousel_container"
      animated
      navigation
      infinite
      :autoplay="autoplay"
      arrows
      transition-prev="slide-right"
      transition-next="slide-left"
      swipeable
      @mouseenter="autoplay = false"
      @mouseleave="autoplay = true"
    >
      <q-carousel-slide
        class="carousel_slide"
        v-for="slide in slides"
        :key="slide.rowId"
        :name="slide.index"
        v-bind:img-src="slide.image"
      />
    </q-carousel>
  </div>
  <div class="mp_container">
      <div class="mp_headerimage">
          <div class="mp_img_text">
              <div class="mp_title">
                  {{ $t("mp_fewWordsTitle") }}
              </div>
              <div class="mp_input_text2">
                  {{ $t("mp_fewWordsTxt") }}
              </div>
          </div>
      </div>
      <div class="mp_headerpanel">
          <div class="mp_title">
              {{ $t("mp_servicesTitle") }}
          </div>
          <div class="mp_input_text">
              {{ $t("mp_servicesTxt") }}
          </div>
      </div>
      <div class="mp_container1">
          <div class="mp_panel1" />
          <div class="mp_panel2">
              <div class="mp_title">
                  {{ $t("mp_constractionsTitle") }}
              </div>
              <div class="mp_input_text">
                  {{ $t("mp_constractionsTxt") }}
              </div>
          </div>
      </div>
      <div class="mp_container2">
          <div class="mp_panel3">
              <div class="mp_title">
                  {{ $t("mp_experienceTitle") }}
              </div>
              <div class="mp_input_text">
                  {{ $t("mp_experienceTxt") }}
              </div>
          </div>
          <div class="mp_panel4" />
      </div>
      <div class="mp_panel5">
          <div style="padding-bottom: 10px; padding-top: 10px;" class="mp_title">
              Our Partners
          </div>
          <div class="mp_panel5_row">
              <div style="width: 50%" class="mp_panel5_column">
                  <img src="/uploads/partners/2023/6/30/Kagioulis_logo.png" style="width: 30%">
              </div>
              <div style="width: 50%" class="mp_panel5_column">
                  <img src="/uploads/partners/2023/6/30/Arestis_logo.jpg" alt="Forest" style="width: 30%">
              </div>
          </div>
      </div>
      <!--<div style="height: 20px">

      </div>-->
      <div class="mp_panel5">
          <div style="padding-bottom: 10px; padding-top: 10px;" class="mp_title">
              Our Certifications
          </div>
          <div class="mp_panel5_row">
              <div class="mp_panel5_column">
                  <img src="/uploads/ISO/2023/6/30/ISO_45001.png" style="width: 30%">
              </div>
              <div class="mp_panel5_column">
                  <img src="/uploads/ISO/2023/6/30/ISO_9001.png" alt="Forest" style="width: 30%">
              </div>
              <div class="mp_panel5_column">
                  <img src="/uploads/ISO/2023/6/30/ISO_14001.png" alt="Mountains" style="width: 30%">
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { ref } from "vue";
import carouselService from "../services/carouselService";
import { useStore } from "vuex";

export default {
  name: "MainPage",
  setup() {
    const store = useStore();
    store.dispatch("show");
    setTimeout(() => {
      store.dispatch("hide");
    }, 500);
    var slides = ref([]);
    // const test = [
    //   {
    //     id: 1,
    //     url: ".././assets/photo2.jpg",
    //   },
    // ];
    return {
      autoplay: ref(true),
      slide: ref("0"),
      slides,
      // test
    };
  },
  data() {
    return {
      language: "el",
    };
  },
  created() {},
  mounted() {
    if (this.$route.params.data != null) {
      this.language = this.$route.params.data;
    }
    this.fetchPhotos();
  },
  methods: {
    async fetchPhotos() {
      carouselService
        .getAll()
        .then((response) => {
          this.slides = response.data;
          this.slides = this.slides.slice().sort(function (a, b) {
            return a.priority - b.priority;
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel_container {
  height: 89vh;
}

.carousel_slide {
  background-size: cover;
}

.mp_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: auto;
}

.mp_title {
  font-size: 35px;
  font-weight: bold;
}

.mp_input_text {
  font-size: 25px;
  text-align: center;
}

.mp_input_text2 {
  font-size: 25px;
  text-align: left;
  white-space: pre-wrap;
}

.mp_headerpanel {
  background: #ededed;
  width: 100%;
  font-family: "Arial";
  font-size: 30px;
  padding: 90px;
  max-height: 100%;
}

.mp_headerimage {
  background: url("/uploads/wbp-content/wbp-background2.jpg");
  //background-size: cover;
  //background-attachment: fixed;
  width: 100%;
  min-height: 100%;
}

.mp_container2,
.mp_container1 {
  display: flex;
  flex-direction: row;
  height: auto;
}

.mp_panel1 {
  background: url("/uploads/wbp-content/wbp-background3.jpg");
  //background-size: cover;
  //background-attachment: fixed;
  width: 50%;
  height: 100%;
}

.mp_panel3,
.mp_panel2 {
  width: 50%;
  min-height: 50%;
  max-height: 100%;
  background: #24262b;
  color: #fdfdfd;
  padding: 90px;
  font-family: "Arial";
  font-size: 18px;
  text-align: center;
}

.mp_panel4 {
  background: url("/uploads/wbp-content/wbp-background4.jpg");
  //background-size: cover;
  //background-attachment: fixed;
  width: 50%;
  height: 100%;
}

    .mp_panel5 {
        /*background: url("/uploads/wbp-content/wbp-background5.jpg");
    background-size: cover;
    background-attachment: fixed;*/
        background: white;
        width: 100%;
        height: 100%;
        padding-bottom: 45px;
    }

.mp_panel5_column {
    float: left;
    width: 33.33%;
    padding: 5px;
}

.mp_panel5row::after {
    content: "";
    clear: both;
    display: table;
}

.bg-text {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  width: 100%;
  text-align: center;
  min-height: 100%;
  font-family: "Arial";
}

.mp_img_text {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  width: 100%;
  text-align: center;
  padding: 90px;
  min-height: 100%;
  font-family: "Arial";
}

@media screen and (max-width: 1550px) {
  .mp_img_text,
  .mp_headerpanel,
  .mp_panel3,
  .mp_panel2 {
    padding: 70px;
  }
}

@media screen and (max-width: 1260px) {
  .mp_img_text,
  .mp_headerpanel,
  .mp_panel3,
  .mp_panel2 {
    padding: 40px;
  }
}

@media screen and (max-width: 1139px) {
  .mp_img_text,
  .mp_headerpanel,
  .mp_panel3,
  .mp_panel2 {
    padding: 20px;
  }
  .mp_title {
    font-size: 25px;
  }
  .mp_input_text,
  .mp_input_text2 {
    font-size: 18px;
  }
  .mp_headerpanel {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .mp_container1 {
    display: unset;
    height: 100%;
  }
  .mp_container2 {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
  }
  .mp_panel4,
  .mp_panel1 {
    width: 100%;
    height: 350px;
  }
  .mp_img_text,
  .mp_headerpanel,
  .mp_panel3,
  .mp_panel2 {
    width: 100%;
    padding: 40px;
  }
  .carousel_container {
    height: 93vh;
  }
}

@media screen and (max-width: 500px) {
  .mp_img_text,
  .mp_headerpanel,
  .mp_panel3,
  .mp_panel2 {
    padding: 30px;
  }
}
</style>

<template>
  <header class="q-header q-layout__section--marginal fixed-top" style="height: 100%">
    <q-layout view="lHh lpR lFf" container style="height: 100%" class="shadow-2">
      <q-header elevated class="bg-grey-1 text-black">
        <q-toolbar>
          <q-btn dense flat round icon="menu" @click="toggleLeftDrawer" />
          <div style="width: 100%; display: flex">
            <q-btn-dropdown style="margin-left: auto; margin-right: 0" color="#f5f5f5" text-color="black" icon="settings">
              <q-list>
                <q-item clickable v-close-popup @click="onLogoutClick">
                  <q-item-section avatar>
                    <q-icon name="logout" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Logout</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
          <q-toolbar-title />
        </q-toolbar>
      </q-header>

      <q-drawer v-model="leftDrawerOpen" show-if-above side="left" bordered elevated class="bg-blue-grey-9 text-grey-1">
        <q-scroll-area class="fit">
          <q-item>
            <div style="font-weight: bold; font-size: 18px; font: 'Arial'">
              <img src="../../assets/WBlogo.gif" style="width: 100%; height: 50px" />
              <div style="text-align: start; padding-top:10px; padding-bottom:15px;">WbPortfolio</div>
            </div>
          </q-item>
          <q-list class="on-left">
            <q-item v-ripple clickable @click="calculateComponent('PhotoCarousel')">
              <q-item-section avatar>
                <q-icon name="view_carousel" />
              </q-item-section>
              <q-item-section>Carousel</q-item-section>
            </q-item>
            <q-item v-ripple clickable @click="calculateComponent('Services')">
              <q-item-section avatar>
                <q-icon name="design_services" />
              </q-item-section>
              <q-item-section>Services</q-item-section>
            </q-item>
            <q-item v-ripple clickable @click="calculateComponent('Blog')">
              <q-item-section avatar>
                <q-icon name="rss_feed" />
              </q-item-section>
              <q-item-section>Blog</q-item-section>
            </q-item>
            <q-item v-ripple clickable @click="calculateComponent('Users')">
              <q-item-section avatar>
                <q-icon name="people" />
              </q-item-section>
              <q-item-section>Users</q-item-section>
            </q-item>
          </q-list>
        </q-scroll-area>
      </q-drawer>

      <q-page-container>
        <q-page padding class="bg-grey-2">
          <!-- Content -->
          <div id="content">
            <component :is="currentComponet" />
          </div>
        </q-page>
      </q-page-container>
    </q-layout>
  </header>
</template>

<script>
import { ref } from "vue";
import PhotoCarousel from "./PhotoCarousel.vue";
import Blog from "./Blog.vue";
import Services from "./ServicesPopup.vue";
import Users from "./Users.vue";
import logoutService from "../../services/logoutService";

export default {
  name: "Dashboard",
  components: {
    PhotoCarousel,
    Blog,
    Services,
    Users,
  },
  setup() {
    const leftDrawerOpen = ref(false);

    return {
      leftDrawerOpen,
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },
    };
  },
  data() {
    return {
      hide: true,
      currentComponet: "PhotoCarousel",
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  created() {},
  methods: {
    calculateComponent(component) {
      this.currentComponet = component;
    },
    onLogoutClick() {
      logoutService
        .create()
        .then((response) => {
          this.$router.push("/Login");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {});
    },
  },
};
</script>

<style lang="sass" scoped>
.menu-list .q-item
  border-radius: 0 32px 32px 0
</style>

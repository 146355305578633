<template>
  <div class="q-pa-md">
    <div class="blg_header_container">
      <div class="blg_add_btn">
        <q-btn
          class="blg_btn_style"
          color="light-blue-9"
          label="Add"
          icon="add"
          @click="onAddClick"
        />
      </div>
      <div class="blg_search">
        <q-input
          class="blg_search_style"
          bg-color="white"
          rounded
          outlined
          v-model="SearchText"
          label="Search"
        />
      </div>
    </div>
    <q-table
      title="Blog"
      :loading="loading"
      :rows="rows"
      :columns="columns"
      row-key="name"
      @row-click="onRowClick"
      :rows-per-page-options="[10]"
      style="width: 100%;"
    >
      <template #loading>
        <q-inner-loading showing color="primary" />
      </template>
    </q-table>
  </div>

  <q-dialog
    v-model="dialogvalue"
    transition-show="scale"
    transition-hide="scale"
    no-esc-dismiss
    no-backdrop-dismiss
    maximized
  >
    <q-card class="blg_card">
      <div class="blg_master_container">
        <div style="width: 50%">
          <img
            v-if="ImageActive"
            class="blg_img"
            :key="selectedItem.rowId"
            :src="imgsrc"
          />
          <img
            v-if="!ImageActive"
            class="blg_img"
            :src="'/uploads/wbp-content/noImg.png'"
          />
        </div>
        <q-btn
          v-close-popup
          push
          color="red-5"
          round
          icon="close"
          class="absolute"
          style="z-index: 99; top: 0%"
          @click="fetchBlogs()"
        />
        <div class="blg_top_right_container">
          <div class="blg_main_data">
            <div class="blg_id_container">
              <q-input
                class="blg_id_input"
                outlined
                v-model.number="selectedItem.id"
                label="Id"
                stack-label
                type="number"
                disable
                readonly
              />
            </div>
            <div class="blg_blogdate_container">
              <q-input
                class="blg_blogdate_input"
                outlined
                v-model="selectedItem.blogdate"
                mask="date"
                label="Blog Date"
                stack-label
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      cover
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date v-model="selectedItem.blogdate">
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>
          <div class="blg_title_container">
            <q-input
              class="blg_title_input"
              outlined
              v-model="selectedItem.title"
              label="Title"
              maxlength="128"
              stack-label
              required
            />
          </div>
          <div class="blg_insert_data">
            <div class="blg_insertuser_container">
              <q-input
                outlined
                v-model="selectedItem.insertUser"
                label="Insert User"
                disable
                readonly
                stack-label
                class="blg_insertuser_input"
              />
            </div>
            <div class="blg_insdate_container">
              <q-input
                class="blg_insdate_input"
                outlined
                v-model="selectedItem.insDate"
                label="Insert Date"
                mask="date"
                disable
                readonly
                stack-label
              />
            </div>
          </div>
          <div class="blg_update_data">
            <div class="blg_updateuser_container">
              <q-input
                class="blg_updateuser_input"
                outlined
                v-model="selectedItem.updateUser"
                label="Update User"
                disable
                readonly
                stack-label
              />
            </div>
            <div class="blg_upddate_container">
              <q-input
                class="blg_upddate_input"
                outlined
                v-model="selectedItem.updDate"
                label="Update Date"
                mask="date"
                disable
                readonly
                stack-label
              />
            </div>
          </div>
        </div>
      </div>
      <q-card-section>
        <q-btn
          class="bg-teal text-white"
          push
          label="Upload"
          icon="file_upload"
          @click="onPickFile"
        />
        <input
          class="blg_fileinput"
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="onFilePicked"
        />
      </q-card-section>
      <q-card-section>
        <div class="blg_desc_title">Description GR</div>
        <div class="row no-wrap items-center">
          <q-editor
            class="blg_editor"
            v-model="this.description"
            :model-value="this.description"
            :dense="$q.screen.lt.md"
            :toolbar="[
              [
                {
                  label: $q.lang.editor.align,
                  icon: $q.iconSet.editor.align,
                  fixedLabel: true,
                  options: ['left', 'center', 'right', 'justify'],
                },
              ],
              ['bold', 'italic', 'strike', 'underline'],
              ['token', 'hr', 'link', 'custom_btn'],
              [
                {
                  label: $q.lang.editor.fontSize,
                  icon: $q.iconSet.editor.fontSize,
                  fixedLabel: true,
                  fixedIcon: true,
                  list: 'no-icons',
                  options: [
                    'size-1',
                    'size-2',
                    'size-3',
                    'size-4',
                    'size-5',
                    'size-6',
                    'size-7',
                  ],
                },
              ],
              ['unordered', 'ordered'],
              ['undo', 'redo'],
            ]"
          />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions class="blg_card_actions">
        <q-btn
          v-close-popup
          push
          color="light-blue-9"
          label="Submit"
          icon="done"
          @click="onSubmit"
        />
        <q-btn
          v-close-popup
          push
          color="red"
          label="Delete"
          icon="delete"
          @click="onDelete"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import blogsService from "../../services/blogsService";
import { ref } from "vue";

export default {
  name: "Blog",
  setup() {
    const rows = ref([]);
    const loading = ref(false);
    const ImageActive = ref(false);
    const selectedItem = ref({});
    const imgChange = ref("");
    const description = ref("");
    const imgsrc = ref("");
    const fileExt = ref("");
    var blogdate = new Date();
    const SearchText = ref("");
    const dialogvalue = ref(false);
    return {
      columns: [
        {
          name: "id",
          align: "left",
          label: "Id",
          field: "id",
          sortable: true,
        },
        {
          name: "title",
          align: "left",
          label: "Title",
          field: "title",
          sortable: true,
        },

        {
          name: "blogdate",
          align: "left",
          label: "Blog Date",
          field: "blogdate",
          sortable: true,
        },
      ],
      rows,
      loading,
      ImageActive,
      selectedItem,
      imgChange,
      description,
      imgsrc,
      fileExt,
      blogdate,
      SearchText,
      dialogvalue,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.fetchBlogs();
  },
  created() {},
  methods: {
    async fetchBlogs() {
      this.loading = true;

      blogsService
        .getAll()
        .then((response) => {
          this.rows = response.data;
          const arr = this.rows;
          for (var i = 0; i < arr.length; i++) {
            arr[i].blogdate = arr[i].blogdate
              .substr(0, 10)
              .replaceAll("-", "/");
          }
          this.rows = arr;
        })
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClick(evt, row) {
      this.selectedItem = row;
      this.imgsrc = row.image;
      const imgactive = this.imgsrc;
      if (imgactive != null && imgactive.length > 0) {
        this.ImageActive = true;
      } else {
        this.ImageActive = false;
      }
      this.description = row.description;
      this.blogdate = row.blogdate;
      this.imgChange = null;
      this.dialogvalue = true;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    onFilePicked(event) {
      const files = event.target.files;
      let filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Please add a valid file!");
      }
      this.fileExt = filename.split(".").pop();
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.addEventListener("load", () => {
        this.imgChange = fileReader.result;
        this.imgsrc = fileReader.result;
        this.ImageActive = true;
      });
    },
    onSubmit() {
      if (this.imgChange != null && this.imgChange != "") {
        this.selectedItem.imagebytes = this.imgChange.substr(
          this.imgChange.lastIndexOf("base64,") + 7,
          this.imgChange.length
        );
        this.selectedItem.image_ext = this.fileExt;
      } else {
        this.selectedItem.imagebytes = null;
        this.selectedItem.image_ext = null;
      }
      this.selectedItem.description = this.description;
      this.selectedItem.blogdate = this.selectedItem.blogdate.replaceAll(
        "/",
        "-"
      );

      blogsService
        .create(this.selectedItem)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchBlogs();
        });
    },
    onAddClick() {
      this.selectedItem = ref({});
      this.description = ref("");
      this.imgChange = ref("");
      this.ImageActive = false;
      this.imgsrc = ref("");
      this.dialogvalue = true;
    },
    onDelete() {
      this.loading = true;
      blogsService
        .delete(this.selectedItem.id)
        .then((response) => {})
        .catch((e) => {
          alert(e);
        })
        .finally(() => {
          this.loading = false;
          this.fetchBlogs();
        });
    },
  },
};
</script>
<style scoped>
.blg_header_container {
  display: flex;
  padding: 10px 0 10px;
  width: 100%;
}
.blg_add_btn {
  display: flex;
  width: 50%;
  align-items: center;
}
.blg_btn_style {
  height: 30px;
}
.blg_search {
  display: flex;
  width: 50%;
}
.blg_search_style {
  width: 300px;
  margin-left: auto;
  margin-right: 0;
}
.blg_card {
  width: 900px;
  height: 83%;
  background-color: #f5f5f5;
}
.blg_master_container {
  display: flex;
  width: 100%;
}
.blg_img {
  height: 394px;
  width: 100%;
}
.blg_top_right_container {
  display: unset;
  padding: 10px 10px 0;
  width: 50%;
}
.blg_main_data {
  display: flex;
  width: 100%;
}
.blg_id_container {
  width: 50%;
}
.blg_id_input {
  width: 120px;
  background-color: white;
}
.blg_blogdate_container {
  padding-left: 0px;
  width: 50%;
}
.blg_blogdate_input {
  width: 100%;
  background-color: white;
}
.blg_title_container {
  width: 100%;
  padding-top: 35px;
}
.blg_title_input {
  width: 100%;
  background-color: white;
}
.blg_insert_data {
  width: 100%;
  padding-top: 96px;
  display: flex;
}
.blg_insertuser_container {
  width: 50%;
}
.blg_insertuser_input {
  width: 100%;
  background-color: white;
}
.blg_insdate_container {
  width: 50%;
  padding-left: 25px;
}
.blg_insdate_input {
  width: 100%;
  background-color: white;
}
.blg_update_data {
  width: 100%;
  padding-top: 35px;
  display: flex;
}
.blg_updateuser_container {
  width: 50%;
}
.blg_updateuser_input {
  width: 100%;
  background-color: white;
}
.blg_upddate_container {
  width: 50%;
  padding-left: 25px;
}
.blg_upddate_input {
  width: 100%;
  background-color: white;
}
.blg_fileinput {
  display: none;
}
.blg_desc_title {
  text-align: center;
  font-size: 20px;
}
.blg_editor {
  width: 100%;
}
.blg_card_actions {
  justify-content: right;
}
@media screen and (max-width: 900px) {
  .blg_card {
    width: 100%;
  }
}
@media screen and (max-width: 620px) {
  .blg_img {
    height: 100%;
  }
  .blg_card {
    height: 100%;
  }
  .blg_main_data,
  .blg_insert_data,
  .blg_update_data {
    display: unset;
  }
  .blg_blogdate_container,
  .blg_title_container,
  .blg_insertuser_container,
  .blg_insdate_container,
  .blg_updateuser_container,
  .blg_upddate_container {
    width: 100%;
    padding-top: 15px;
    padding-left: 0px;
  }
}
</style>

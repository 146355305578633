<template>
  <div class="app">
    <div class="cmp_page">
      <div class="cmp_title">
        {{ $t('cmp_title') }}
        <hr class="cmp_hr">
      </div>
      <div class="cmp_p1">
        {{$t('cmp_p1')}}
      </div>
      <!--<div class="cmp_title">
        Υπηρεσίες
        <hr class="cmp_hr">
      </div>
      <div class="cmp_services">
        <div class="cmp_panel1">
            <div class="cmp_p1">
                
            </div>
        </div>
        <div class="cmp_panel2" />
      </div>-->
    </div>
  </div>
</template>

<script >
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    store.dispatch("show");
    setTimeout(() => {
      store.dispatch("hide");
    }, 500);
  }
}
</script>

<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

    .cmp_page {
        background-color: #ffff;
        position: relative;
        font-family: "Arial";
        padding: 70px 50px 70px 50px;
        min-height: 500px;
    }

.cmp_services {
  display: flex;
}

.cmp_title {
  font-size: 30px;
  text-align: left;
  padding-top: 4%;
  color: black;
  font-family: "Arial";
}

.cmp_hr {
  display: block;
  background-color: lightgrey;
  color: lightgrey;
  width: 70%;
  height: 1px;
  border: 0;
  margin: 0 0;
}

.cmp_p1 {
  font-size: 20px;
  font-weight: 100;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 20px;
}

.cmp_panel1 {
  order: 1;
  width: 50%;
  display: relative;
}

.cmp_panel2 {
  order: 2;
  background: url("/uploads/wbp-content/wpb-background2.jpg") no-repeat center;
  background-size: cover;
  width: 50%;
  height: 285px;
}

@media screen and (max-width: 1550px) {
  .app {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1330px) {
  .app {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 1130px) {
  .app {
    padding: 0 30px 10px 30px;
  }
}

@media screen and (max-width: 1070px) {
  .app {
    padding: 0 10px 5px 10px;
  }
  .cmp_page {
    padding: 30px 25px 30px 25px;
  }
}

@media screen and (max-width: 980px) {
  .app {
    padding: 0;
    display: unset;
  }

  .cmp_page {
    padding: 30px 10px 30px 10px;
  }

  .cmp_services {
    display: flex;
    flex-direction: column-reverse;
  }
  .cmp_p1 {
    min-width: 100%;
  }
  .cmp_panel1 {
    min-width: 100%;
  }

  .cmp_panel2 {
    min-width: 100%;
    padding: 0;
  }
}




// @media screen and (max-width: 1100px) {
//   .company {
//     padding: 0;
//   }

//   .cmp_services {
//     display: unset;
//   }

//   .cmp_panel1 {
//     width: 100%;
//   }

//   .cmp_panel2 {
//     width: 100%;
//   }
// }
</style>

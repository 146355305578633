<template>
  <div>
    <Navigation />
    <div v-if="loading" class="overlay">
      <div v-if="loading" class="loader"></div>
    </div>
    <div :class="{ hide: loading }">
      <notifications
        group="foo-velocity"
        animation-name="v-fade-right"
        position="top right"
        :duration="2700"
      />
      <router-view />
      <FooterForm />
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import FooterForm from "./components/footer.vue";
import { useStore } from "vuex";

export default {
  components: {
    Navigation,
    FooterForm,
  },
  setup() {},
  computed: {
    loading() {
      return useStore().getters.loadingStatus;
    },
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Your Website";
    },
  },
};
</script>

<style lang="scss">
.hide {
  display: none;
}

.headernav {
  z-index: -1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  // background: url("./assets/photo101.jpg") no-repeat center;
  // background-size: cover;
}
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
  z-index: 99;
}

.v-fade-right-enter-active,
.v-fade-right-leave-active,
.v-fade-right-move {
  transition: all 0.5s;
}

.v-fade-right-enter,
.v-fade-right-leave-to {
  opacity: 0;
  transform: translateX(500px) scale(0.2);
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>

import api from "../api";

 class CarouselService {
  async getAll() {
    return api.get("/DashBoard/Carousel");
  }

  async get(id) {
    return api.get(`/DashBoard/Carousel/${id}`);
  }

  async create(data) {
     return api.post("/DashBoard/Carousel", data);
   }

   async delete(id) {
     return api.delete(`/DashBoard/Carousel/${id}`);
    }
//    put(data) {
//      return api.post(`/DashBoard/Images`, data);
//    }



//   deleteAll() {
//     return http.delete(`/tutorials`);
//   }

//   findByTitle(title) {
//     return http.get(`/tutorials?title=${title}`);
//   }
}

export default new CarouselService();
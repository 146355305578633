import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router/router'
import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import el from './locales/el.json'
import axios from 'axios'
import { locale } from 'core-js'
import store from "./store";
import { VueCookieNext } from 'vue-cookie-next';
import VueEasyLightbox from 'vue-easy-lightbox';
import Notifications from '@kyvg/vue3-notification';

const lang = localStorage.getItem('lang') || 'el';
const token = localStorage.getItem('token');

const i18n = createI18n({
    locale: lang,
    messages: {
      en : en,
      el: el,
    }
  })

VueCookieNext.config({ expire: '1d' })
const app = createApp(App);
app.use(router).use(Quasar, quasarUserOptions).use(store).use(i18n).use(VueCookieNext).use(VueEasyLightbox).use(Notifications);
router.app = app;
app.mount('#app')
<template>
  <div class="app">
    <div class="page">
      <img
        class="img_notfound"
        src="@/assets/PageNotFound.png"
        @click="goToHome()"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  methods: {
      goToHome() {
      this.$router.push('/');
    },
  }
};
</script>

<style lang="scss" scoped>
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.page {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
  width: auto;
  height: auto;
}

.img_notfound {
    height:400px;
}

.img_notfound:hover {
    cursor: pointer;
}

@media screen and (max-width: 1550px) {
  .app {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1260px) {
  .app {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 925px) {
  .app {
    padding: 0 30px 10px 30px;
  }
}

@media screen and (max-width: 880px) {
  .app {
    padding: 0;
  }

  .page {
    padding: 30px 10px 30px 10px;
  }
}

@media screen and (max-width: 650px) {
  .img_notfound {
      height: 215px;
  }
}
</style>

<template>
  <div class="app">
    <div class="page">
      <div v-for="row in rows.slice(findex, lindex)" :key="row.id">
        <div style="width: 100%">
          <p
            class="p21"
            @click="openLink(row.id + '&' + stringtourl(row.title))"
          >
            {{ row.title }}
          </p>
          <div class="blogdate">
            {{ row.blogdate }}
          </div>
        </div>
        <div class="entry-content">
          <div class="left-container">
            <div class="completed_data">
              <p class="p2">
                <span v-html="row.description"></span>
              </p>
            </div>
            <div class="btn-pad">
              <q-btn
                outline
                class="btn_view_more"
                label="Περισσοτερα"
                @click="openLink(row.id + '&' + stringtourl(row.title))"
              />
            </div>
          </div>
          <div v-if="checkLength(row.image)" class="right-container">
            <img
              :src="row.image"
              style="background-size: cover; width: 100%; height: 350px"
              :key="row.rowId"
            />
          </div>
          <div v-if="!checkLength(row.image)" class="right-container">
            <div style="width: 100%; height: 350px" />
          </div>
        </div>
      </div>
      <div class="paginate_div">
        <paginate
          class="paginate_class"
          :page-count="this.pageCount"
          :margin-pages="2"
          :page-range="3"
          :click-handler="clickCallback"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import blogsService from "../services/blogsService";
import Paginate from "vuejs-paginate-next";
import { useStore } from "vuex";

export default {
  routername: "Blogs",
  components: { Paginate },
  setup() {},
  data() {
    return {
      service: "",
      categ1: "",
      categ2: "",
      rows: ref([]),
      page: 1,
      pageCount: 0,
      findex: 0,
      lindex: 5,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    clickCallback(pageNum) {
      if (this.page < pageNum) {
        this.findex += (pageNum - this.page) * 5;
        this.lindex += (pageNum - this.page) * 5;
      } else {
        this.findex -= (this.page - pageNum) * 5;
        this.lindex -= (this.page - pageNum) * 5;
      }

      this.page = pageNum;
      window.scrollTo(0, 0);
    },
    fetchData() {
      const store = useStore();
      store.dispatch("show");

      blogsService
        .getAll()
        .then((response) => {
          this.rows = response.data;
          this.pageCount = this.rows.length / 5;

          this.rows = this.rows.slice().sort(function (a, b) {
            return new Date(b.blogdate) - new Date(a.blogdate);
          });

          const arr = this.rows;
          for (var i = 0; i < arr.length; i++) {
            arr[i].blogdate = arr[i].blogdate
              .substr(0, 10)
              .replaceAll("-", "/");

            arr[i].description =
              arr[i].description
                .replaceAll(/(<([^>]+)>)/gi, "")
                .replaceAll("&nbsp;", " ")
                .split(" ")
                .slice(0, 25)
                .join(" ") + " [...]";
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            store.dispatch("hide");
          }, 500);
        });
    },
    stringtourl(string) {
      string = string
        .trim()
        .toLowerCase()
        .replaceAll(" ", "-")
        .replace(/\//g, "-")
        .replace(/\\/g, "-");
      return string;
    },
    openLink(link) {
      this.$router.push("/Blogs/" + link);
    },
    checkLength(string) {
      if (string == null) {
        return false;
      }
      if (string.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="css">
.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg");
  background-size: contain;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.page {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
  width: 100%;
}

.entry-content {
  display: flex;
  height: auto;
}

.completed_data {
  margin-bottom: 60px;
}

.left-container {
  flex: 0;
  flex-basis: calc(60% - 10px);
  max-width: calc(60% - 10px);
  position: relative;
}

.right-container {
  flex: 0;
  flex-basis: calc(40% - 15px);
  max-width: calc(40% - 15px);
  position: relative;
  padding-left: 25px;
  text-align: left;
}

.panel2 {
  background-size: cover;
  min-width: 100%;
  min-height: 350px;
}

.p21 {
  font-size: 20px;
  text-align: left;
  font-weight: 300;
  color: black;
  font-family: "Arial";
  padding-top: 30px;
  width: fit-content;
}

.p21:hover {
  cursor: pointer;
  color: #fcd088;
  transition: color 0.3s;
}

.blogdate {
  font-size: 15px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-bottom: 10px;
  font-style: oblique;
}

.p2 {
  font-size: 19px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 10px;
}

.btn_view_more {
  color: goldenrod;
  width: 150px;
  height: 54px;
  float: left;
}

.btn-pad {
  padding-top: 20px;
}
.paginate_class {
  list-style: none;
}
.paginate_div {
  display: flex;
  justify-content: flex-end;
  padding-left: 0;
  padding-top: 50px;
}
.page-item {
  color: black;
  float: left;
  text-decoration: none;
}
.page-item.active .page-link {
  background-color: #4caf50;
  color: white;
}
.page-link {
  padding: 0.375rem 0.75rem;
  position: relative;
  display: block;
  color: black;
  background-color: rgb(255, 255, 255);
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(222, 226, 230);
  border-image: initial;
  transition: color 0.15s ease-in-out 0s, background-color 0.15s ease-in-out 0s,
    border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.page-link:hover {
  background-color: #ddd;
  cursor: pointer;
}

@media screen and (max-width: 1550px) {
  .app {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1330px) {
  .app {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 1130px) {
  .app {
    padding: 0 30px 10px 30px;
  }
}

@media screen and (max-width: 1070px) {
  .app {
    padding: 0 10px 5px 10px;
  }
  .page {
    padding: 30px 25px 30px 25px;
  }
}

@media screen and (max-width: 980px) {
  .app {
    padding: 0;
    display: unset;
  }

  .page {
    padding: 30px 10px 30px 10px;
  }

  .entry-content {
    display: flex;
    flex-direction: column-reverse;
  }

  .left-container {
    max-width: 100%;
  }

  .right-container {
    max-width: 100%;
    padding: 0;
  }
}
</style>

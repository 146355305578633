import api from "../api";

class blogsService {
  async getAll() {
    return api.get("/DashBoard/Blogs");
  }

  async get(id) {
    return api.get(`/DashBoard/Blogs/${id}`);
  }

  async create(data) {
    return api.post("/DashBoard/Blogs/", data);
  }
  async delete(id) {
    return api.delete(`/DashBoard/Blogs/${id}`);
   }
}

export default new blogsService();
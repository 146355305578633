<template>
  <div class="app_blg">
    <div class="page_blg">
      <div class="back_container_blg" @click="GoBack()">
        <div class="back_ico_blg">
          <q-icon name="arrow_back" size="20px"> </q-icon>
          <div class="back_txt_blg">Back</div>
        </div>
      </div>
      <p class="p1_blg">
        {{ row.title }}
      </p>
      <div class="blogdate">
        {{ row.blogdate }}
      </div>
      <div class="entry_content_blg">
        <div v-if="checkLength(row.image)" class="right_container_blg">
          <img :src="row.image" class="img_stl_blg" :key="row.rowId" />
        </div>
        <div v-if="!checkLength(row.image)" style="height: 0px">
        </div>
        <div class="left_container_blg">
          <div class="completed_data_blg">
            <p class="p2_blg">
              <span v-html="row.description"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from "vue";
import blogsService from "../services/blogsService";
import { useStore } from "vuex";

export default {
  routername: "Blog",
  setup() {},
  data() {
    return {
      blog: "",
      id: "",
      row: ref([]),
    };
  },
  mounted() {
    const route = useRoute();
    this.blog = route.params.routername;

    this.fetchData();
  },
  methods: {
    fetchData() {
      const store = useStore();
      store.dispatch("show");
      var array = this.blog.split("&");
      this.id = array[0];
      blogsService
        .get(this.id)
        .then((response) => {
          this.row = response.data;

          this.row.blogdate = this.row.blogdate.substr(0, 10).replaceAll("-", "/");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            store.dispatch("hide");
          }, 500);
        });
    },
    checkLength(string) {
      if (string == null) {
        return false;
      }
      if (string.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    GoBack() {
      this.$router.push("/Blogs");
    },
  },
};
</script>

<style>
.app_blg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 260px 50px 260px;
  background: url("/uploads/wbp-content/wbp-background1.jpg") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.page_blg {
  background-color: #ffff;
  position: relative;
  font-family: "Arial";
  padding: 70px 50px 70px 50px;
  width: 100%;
}

.back_container_blg {
  display: flex;
  align-items: center;
}

.back_container_blg :hover {
  cursor: pointer;
  color: #00afea;
  transition: color 0.3s;
}

.back_ico_blg {
  display: flex;
  align-items: center;
}

.back_txt_blg {
  font-size: 17px;
}

.img_stl_blg {
  background-size: cover;
  width: 100%;
  height: auto;
}

.completed_data_blg {
  margin-bottom: 60px;
}

.right_container_blg {
  width: 600px;
  text-align: left;
}

.left_container_blg {
  width: 100%;
  position: relative;
}

.entry_content_blg {
  height: auto;
}

.p1_blg {
  font-size: 20px;
  text-align: left;
  font-weight: 300;
  color: black;
  font-family: "Arial";
  padding-top: 30px;
  width: 100%;
}

.blogdate {
  font-size: 15px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-bottom: 10px;
  font-style: oblique;
}

.p2_blg {
  font-size: 20px;
  text-align: left;
  font-weight: lighter;
  color: grey;
  font-family: "Arial";
  padding-top: 10px;
}

@media screen and (max-width: 1550px) {
  .app_blg {
    padding: 0 160px 10px 160px;
  }
}

@media screen and (max-width: 1330px) {
  .app_blg {
    padding: 0 60px 10px 60px;
  }
}

@media screen and (max-width: 1130px) {
  .app_blg {
    padding: 0 30px 10px 30px;
  }
}

@media screen and (max-width: 1070px) {
  .app_blg {
    padding: 0 10px 5px 10px;
  }
  .page_blg {
    padding: 30px 25px 30px 25px;
  }
}

@media screen and (max-width: 980px) {
  .app_blg {
    padding: 0;
    display: unset;
  }

  .page_blg {
    padding: 30px 10px 30px 10px;
  }
}

@media screen and (max-width: 650px)
{
  .right_container_blg {
    width: 100%;
  }
}
</style>

<template>
  <div class="headernav" :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <div class="branding">
        <img src="@/assets/logo.png" alt="" @click="goToHome" />
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: 'MainPage' }">
            {{ $t("nav_homePage") }}
          </router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'Company' }">
            {{ $t("nav_companyPage") }}
          </router-link>
        </li>
        <li>
          <router-link
            class="link"
            :to="{ name: 'Services' }"
            @click="isOpen = !isOpen"
          >
            {{ $t("nav_servicesPage") }}
          </router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'Contact' }">
            {{ $t("nav_contactPage") }}
          </router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: 'Blogs' }"> Blog </router-link>
        </li>
        <div
          style="
            display: flex;
            text-transform: uppercase;
            padding: 16px;
            margin-left: 16px;
            font-weight: 500;
            font-family: 'Arial';
            color: #fff;
            list-style: none;
            text-decoration: none;
          "
        >
          <div class="nav_lang" @click="updateLang('el')">EL</div>
          <div class="nav_backslash">/</div>
          <div class="nav_lang" @click="updateLang('en')">EN</div>
          <div class="nav_backslash">
            <q-icon style="font-size: 18px; color: #9aa0a6" name="language" />
          </div>
        </div>
      </ul>
      <div class="icon">
        <i
          v-show="mobile"
          class="fas fa-bars"
          :class="{ 'icon-active': mobileNav }"
          @click="toggleMobileNav"
        />
      </div>
      <transition name="mobile-nav">
        <ul
          v-show="mobileNav"
          class="dropdown-nav"
          style="margin-block-start: 0; padding-inline-start: unset"
        >
          <li>
            <router-link class="link" :to="{ name: 'MainPage' }" @click="toggleMobileNav">
              {{ $t("nav_homePage") }}
            </router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Company' }" @click="toggleMobileNav">
              {{ $t("nav_companyPage") }}
            </router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Services' }" @click="toggleMobileNav">
              {{ $t("nav_servicesPage") }}
            </router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Contact' }" @click="toggleMobileNav">
              {{ $t("nav_contactPage") }}
            </router-link>
          </li>
          <li>
            <router-link class="link" :to="{ name: 'Blogs' }" @click="toggleMobileNav">
              Blog
            </router-link>
          </li>
          <li style="display: flex; justify-content: center; position: absolute; bottom: 0px; width: 100%">
            <div class="nav_lang" @click="updateLang('el')">EL</div>
            <div class="nav_backslash">/</div>
            <div class="nav_lang" @click="updateLang('en')">EN</div>
            <div class="nav_backslash">
              <q-icon style="font-size: 18px; color: #9aa0a6" name="language" />
            </div>
          </li>
        </ul>
      </transition>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      active: false,
      language: "en",
      isOpen: false,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    goToHome() {
      this.$router.push({ name: "MainPage", params: { data: this.language } });
    },
    updateScroll() {
      const ScrollPosition = window.scrollY;
      if (ScrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1100) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    updateLang(prop) {
      this.language = prop;
      localStorage.setItem("lang", prop);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.headernav {
  background-color: black;
  z-index: 1;
  width: 100%;
  position: sticky;
  transition: 0.5s ease all;
  color: #fff;
  top: 0;

  .nav_backslash {
    padding-left: 8px;
  }

  .nav_lang {
    padding-left: 8px;
  }

  .nav_lang:hover {
    color: #00afea;
    border-color: #00afea;
    cursor: pointer;
  }

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    transition: 0.5s ease all;
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    img:hover {
      cursor: pointer;
    }

    ul,
    .link {
      font-weight: 500;
      font-family: "Arial";
      color: #fff;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      padding: 16px;
      margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: #00afea;
        border-color: #00afea;
      }
    }
    .branding {
      display: flex;
      align-items: center;

      img {
        width: 220px;
        height: 63px;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;

      i {
        cursor: pointer;
        font-size: 24px;
        transition: 0.8s ease all;
      }
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width: 200px;
      height: 100%;
      align-content: center;
      background-color: #000;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
      top: 0;
      left: 0;

      li {
        margin-left: 0;
        .link {
          color: #fff;
        }
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 1s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }

    .mnobile-nav-enter-to {
      transform: translateY(0);
    }
  }
}

.scrolled-nav {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 8px 0;

    .branding {
      img {
        width: 200px;
        height: 57.26px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateX(2em);
}

.fade-enter-active,
.oute-leave-active {
  transition: all 0.3s ease;
}
</style>

import api from "../api";

class ServiceImageService {
    async get(id) {
        return api.get(`/DashBoard/ServicesImage/${id}`);
    }
    async create(data) {
        return api.post("/DashBoard/ServiceImages/", data);
    }
    async delete(id) {
          return api.delete(`/DashBoard/ServicesImage/${id}`);
    }
}

export default new ServiceImageService();